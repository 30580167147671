jQuery( document ).ready( function( $ ) {
	/* Mobile menu */
	$( ".mobile-navigation-menu" ).mmenu( {
		extensions: [
			"position-right",
		],
		navbar: {
			add: true,
			title: "",
		},
		screenReader: {
			aria: true,
			text: true,
		}
	} )

	$( ".menu-button" ).click( function() {
		if ( $( ".mobile-navigation-menu" ).hasClass( "mm-opened" ) ) {
			$( ".mobile-navigation-menu" ).data( "mmenu" ).close()
		}
		else {
			$( ".mobile-navigation-menu" ).data( "mmenu" ).open()
		}
	} )
} )
